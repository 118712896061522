/* eslint-disable react/no-array-index-key */
import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useIntersection } from 'react-use'
import Tick from '@pqina/flip'
import type { TickInstance } from '@pqina/flip'
import '@pqina/flip/dist/flip.min.css'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'

import LoaderSpinner from 'components/LoaderSpinner'

const formatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  maximumFractionDigits: 0,
  currency: 'GBP',
})

/**
 * [1] Work around slight rendering anomaly where the vertical edges of the
 *     bottom half of a flipper displays a small misalignment in Chrome.
 */
const StyledTickWrapper = styled.div`
  .tick-text-inline,
  .tick-flip-panel {
    color: ${COLOR.BLACK};
    font-family: ${FONT.FAMILY.DECORATIVE};
    font-weight: ${FONT.WEIGHT.REGULAR};
  }

  .tick-text-inline:first-child {
    margin-right: ${GTR.XS};
  }

  .tick-flip {
    width: 1.125em;
    will-change: transform; // [1]
  }

  .tick-flip-panel {
    background-color: ${COLOR.WHITE};
  }

  .tick-flip-shadow {
    box-shadow: 0 0.125em 0.3125em rgba(0, 0, 0, 0.1),
      0 0.02125em 0.06125em rgba(0, 0, 0, 0.1);
  }

  .tick-flip-panel-back:after {
    background-image: none;
  }
`

export type PledgeCounterProps = {
  /**
   * Delay after counter enters the viewport before animating to the current
   * value.
   */
  animationDelay?: number
  /**
   * By default the counter will start at the previous pledge count as reported
   * by the API endpoint. If `useFakeInitialValue` is set to `true`, the counter
   * will start at a random value between `initialValueMin` and
   * `initialValueMax`.
   */
  useFakeInitialValue?: boolean
  /**
   * If `useFakeInitialValue` is `true`, sets the minimum value for the random
   * difference between the previous and current pledge counts. Not used if
   * `useFakeInitialValue` is `false`.
   */
  initialValueMin?: number
  /**
   * If `useFakeInitialValue` is `true`, sets the maximum value for the random
   * difference between the previous and current pledge counts. Not used if
   * `useFakeInitialValue` is `false`.
   */
  initialValueMax?: number
}

const PledgeCounter = ({
  animationDelay = 1000,
  useFakeInitialValue = true,
  initialValueMin = 50_000,
  initialValueMax = 500_000,
}: PledgeCounterProps): React.ReactElement => {
  const intersectionRef = useRef(null)
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  })
  const intersectionRatio = intersection?.intersectionRatio
  const tickRef = useRef<HTMLDivElement | null>(null)
  const tickInstance = useRef<TickInstance>()
  const timeoutRef = useRef<number | null>(null)
  const { pledgeCount } = useStaticQuery<{
    pledgeCount: {
      id: string
      previous: number
      current: number
    }
  }>(graphql`
    query {
      pledgeCount {
        id
        previous
        current
      }
    }
  `)

  const [initialValue] = useState<number>(() => {
    if (useFakeInitialValue) {
      return (
        pledgeCount.current -
        (Math.floor(Math.random() * (initialValueMax - initialValueMin + 1)) +
          initialValueMin)
      )
    }

    return pledgeCount.previous
  })

  useEffect(() => {
    if (!tickRef.current) {
      return
    }

    tickInstance.current = Tick.DOM.create(tickRef.current, {
      credits: false,
      value: initialValue,
    })

    // eslint-disable-next-line consistent-return
    return () => {
      if (tickRef.current) {
        Tick.DOM.destroy(tickRef.current)
      }
    }
  }, [])

  // Start animating to the current value a short delay after the counter is
  // in view.
  useEffect(() => {
    if (intersectionRatio !== 1) {
      return
    }

    if (intersectionRatio === 1) {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current)
      }

      // Set a new timeout
      timeoutRef.current = window.setTimeout(() => {
        if (tickInstance.current) {
          tickInstance.current.value = pledgeCount.current
        }
      }, animationDelay)

      // eslint-disable-next-line consistent-return
      return () => {
        if (timeoutRef.current) {
          window.clearTimeout(timeoutRef.current)
        }
      }
    }
  }, [intersectionRatio, tickInstance.current])

  if (!pledgeCount) {
    return <LoaderSpinner color={COLOR.ACCENT.PRIMARY_10} />
  }

  const formatted = formatter.format(initialValue)

  return (
    <StyledTickWrapper
      className="tick"
      ref={tickRef}
      aria-label={formatted}
      data-percy-hide
    >
      <div
        aria-hidden
        data-value-mapping="indexes"
        data-layout="horizontal fit"
        data-transform="arrive(20000) -> round -> split -> delay(rtl, 100, 150)"
        ref={intersectionRef}
      >
        {formatted.split('').map((char, index) =>
          Number.isNaN(parseInt(char, 10)) ? (
            <span className="tick-text-inline" key={index}>
              {char}
            </span>
          ) : (
            <span data-view="flip" key={index}>
              {char}
            </span>
          )
        )}
      </div>
    </StyledTickWrapper>
  )
}

export default PledgeCounter
